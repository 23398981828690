import { Typography } from '@/sharedComponents/v1/Components/Typography';
import Image from 'next/legacy/image';
import {
  styAvatar, styContainer, styInfoContainer, styWebMockup,
} from './style';

function LoginTestimonialSection() {
  return (
    <div css={[styContainer]}>
      <Typography type="main" body="display-md-bold" margin="0 0 32px">
        “I just built a landing page without coding in 5
        minutes with Typedream! No code is the future”
      </Typography>
      <div css={[styInfoContainer]}>
        <div css={[styAvatar]} style={{ backgroundImage: 'url("/images/login-avatar.svg")', width: '54px', height: '54px' }} />
        <div>
          <Typography type="main" body="text-xl-semibold">
            Easlo
          </Typography>
          <Typography body="text-md-medium">
            Notion Template Builder
          </Typography>
        </div>
      </div>
      <div css={[styWebMockup]}>
        <Image src="/images/mockup-web-min.jpg" width={911} height={456} layout="fixed" alt="web-illus" />
      </div>
    </div>
  );
}

export default LoginTestimonialSection;
