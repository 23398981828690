import { css } from '@emotion/react';

export const styContainer = css({
  width: '100%',
  maxWidth: '700px',
  margin: '0 80px',
  paddingTop: '70px',
  '@media only screen and (max-width: 1200px)': {
    padding: '70px 20px 0',
  },
});

export const styInfoContainer = css({
  display: 'flex',
  gap: '12px',
  marginBottom: '72px',
});

export const styAvatar = css({
  backgroundColor: 'var(--neutral-25)',
  borderRadius: '100%',
});

export const styWebMockup = css({
  width: '100%',
  '&>span': {
    borderRadius: '16px',
  },
});
